<template>
	<div class="login">
		<div v-if="!sent" class="flex flex-column">
			<h2 class="text-gray-800 font-bold text-lg mb-3 font-nunito">
				Forgot Password
			</h2>
			<p class="w-full text-sm text-gray-700 mb-10 font-nunito">
				Enter your email address and we will send you instructions on
				how to reset your password.
			</p>
			<form class="auth-form" @submit.prevent="onFormSubmit">
				<div class="mb-4">
					<label for="email" class="font-nunito font-medium">
						Email address
					</label>
					<input
						v-model="email"
						required
						type="text"
						name="email"
						class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
						placeholder="Enter your email"
					/>
				</div>

				<div class="flex align-items-center">
					<router-link
						:to="{ name: 'login' }"
						class="font-bold text-gray-700 font-nunito"
						data-cy="login-link"
					>
						Back to Login
					</router-link>
					<button
						type="submit"
						class="flex font-roboto uppercase tracking-wide ml-auto bg-blue-500 hover:bg-blue-700 text-white text-sm items-center font-medium py-2 px-3 rounded focus:outline-none focus:shadow-outline"
					>
						<font-awesome-icon
							class="btn-icon mr-2"
							:icon="['far', 'paper-plane']"
						/>
						<span class="btn-label">Send</span>
					</button>
				</div>
			</form>
		</div>
		<div v-else class="flex flex-column">
			<div class="flex flex-col items-center">
				<font-awesome-icon
					class="text-6xl mb-4"
					:icon="['far', 'envelope-open']"
				/>
				<div
					class=" flex justify-center text-gray-800 font-bold text-lg mb-3 font-nunito"
				>
					Please check your email
				</div>
			</div>

			<p class="w-full text-sm text-gray-700 mb-10 font-nunito">
				A email has been sent to {{ email }}. Please check your email
				for instructions from Clarus Care on how to reset your password.
			</p>
			<router-link
				:to="{ name: 'login' }"
				class="inline-flex ml-auto font-bold text-gray-700 mt-6 font-nunito"
			>
				Back to Login
			</router-link>
		</div>
	</div>
</template>
<script>
export default {
	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			try {
				await this.$api.password().forgot(this.email)

				this.$alert.success('password.forgot')
			} catch (e) {
				this.$alert.error('default.error')
			}

			this.sent = true
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ForgotPassword',

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			email: '',
			sent: false,
		}
	},
}
</script>
